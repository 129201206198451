@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.cdnfonts.com/css/satoshi"); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Satoshi", sans-serif;
}

.main-container {
  background: radial-gradient(
    circle,
    rgba(0, 47, 44, 1) 19%,
    rgba(1, 95, 89, 1) 100%
  );
  background: url("../src/assets/jara-vid.gif");
  background-size: 40% 100%;
  background-repeat: no-repeat;
  background-position: left;
}
.main-container .line{
  display: none;
}
/* for mobile screens can we fix the background video size  so it fits the screen */
@media (max-width: 768px) {
  .main-container {
    background: url("../src/assets/jara-vid.gif");
    background-size: cover;
  }
  .line{
    display: block;
  }
}

@media (max-width: 425px) {
  .main-container {
    background: url("../src/assets/jara-vid.gif");
    background-size: cover;
    background-position: center;
  }
}

/* .bottom-container {
  border-radius: 60px 60px 0 0;
  margin-top: 40px;
} */

.line {
  width: 100px;
  height: 8px;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 0 auto;
  cursor: pointer;
}

.slide-in {
  animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.fade-in {
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tracking-line {
  position: relative;
  padding-left: 35px;
  height: 100px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.tracking-line:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  border-left: 3px dotted #ffffff;
  height: 100%;
  background-image: linear-gradient(to bottom, white 50%, transparent 50%);
  background-size: 100% 20px;
}

.tracking-circle {
  position: absolute;
  left: 0;
  top: -1%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: white;
}

.tracking-circle.completed {
  background: rgb(0 146 137);
}

.tracking-circle.pending {
  background: rgb(0 146 137 / 50%);
}

.expand {
  transition: max-height 0.5s ease-in-out;
}

.collapsed {
  transition: max-height 0.5s ease-in-out;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #2a9444;
  color: #fff;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.dropdown-content.active {
  opacity: 1;
  visibility: visible;
}

.dotted {
  border-top: 5px #faf2f2 dotted;
}

.left {
  background-color: rgba(35, 197, 35, 0);
}
.left.dotted {
  border-left: 1px #fdfdfd dotted;
  border-top: none;
}



/* HTML: <div class="loader"></div> */
.loader {
  width: 90px;
  height: 14px;
  box-shadow: 0 3px 0 #fff;
  position: relative;
  clip-path: inset(-40px 0 -5px)
}
.loader:before {
  content: "";
  position: absolute;
  inset: auto calc(50% - 17px) 0;
  height: 50px;
  --g:no-repeat linear-gradient(#f0d106 0 0);
  background: var(--g),var(--g),var(--g),var(--g);
  background-size: 16px 14px;
  animation:
    l7-1 2s infinite linear,
    l7-2 2s infinite linear;
}
@keyframes l7-1 {
  0%,
  100%  {background-position: 0 -50px,100% -50px}
  17.5% {background-position: 0 100%,100% -50px,0 -50px,100% -50px}
  35%   {background-position: 0 100%,100% 100% ,0 -50px,100% -50px}
  52.5% {background-position: 0 100%,100% 100% ,0 calc(100% - 16px),100% -50px}
  70%,
  98%  {background-position: 0 100%,100% 100% ,0 calc(100% - 16px),100% calc(100% - 16px)}
}
@keyframes l7-2 {
  0%,70% {transform:translate(0)}
  100%  {transform:translate(200%)}
}